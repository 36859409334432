export const parseSize = bytes => {
  const kb = (bytes / 1000).toFixed(2)
  const mb = (kb / 1000).toFixed(2)
  const gb = (mb / 1000).toFixed(2)
  let string = `${bytes}B`
  if (kb > 1) string = `${kb}KB`
  if (mb > 1) string = `${mb}MB`
  if (mb > 1) string = `${mb}MB`
  if (gb > 1) string = `${gb}GB`
  return string
}
